import { Role } from '../permissions/permission-types';

export interface ActiveUser {
  firstName: string;
  lastName: string;
  identificationCode: number;
  rolesWithSpecialRights: string[];
  roles: Role[];
  allRoles: RoleType[];
  companies: number[];
}

export interface RoleType {
  role: string;
}

export interface AuthenticationState {
  activeUser: ActiveUser;
  isAuthLoading: boolean;
}

export enum RoleEnum {
  NONE = '',
  ROLE_USER = 'ROLE_USER',
  ROLE_LANDOWNER = 'ROLE_LANDOWNER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_ADMIN_SURVEYOR = 'ROLE_ADMIN_SURVEYOR', // litsentsi haldur
  ROLE_SURVEYOR = 'ROLE_SURVEYOR',
  ROLE_OFFICIAL = 'ROLE_OFFICIAL',
  ROLE_VALUATOR = 'ROLE_VALUATOR',
  ROLE_ADMIN_MASS_VALUATION = 'ROLE_ADMIN_MASS_VALUATION',
  ROLE_CADASTER_REGISTRAR = 'ROLE_CADASTER_REGISTRAR', // menetleja/katastripidaja
  ROLE_ADMIN_CADASTER_REGISTRAR = 'ROLE_ADMIN_CADASTER_REGISTRAR', // menetlusjuht
  ROLE_KOV_REPRESENTATIVE = 'ROLE_KOV_REPRESENTATIVE', // KOV esindaja
  ROLE_KOV_CADASTER_REGISTRAR = 'ROLE_KOV_CADASTER_REGISTRAR',
  ROLE_KOV_OFFICIAL = 'ROLE_KOV_OFFICIAL',
  ROLE_MEHIS_ADMIN = 'ROLE_MEHIS_ADMIN',
  ROLE_MEHIS_VALUATOR = 'ROLE_MEHIS_VALUATOR',
  ROLE_ORGANIZATION_LANDOWNER = 'ROLE_ORGANIZATION_LANDOWNER',
}
