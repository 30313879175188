import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Grid, MenuItem, Paper, Select } from '@mui/material';
import SearchInput from '../../permissions/sharedComponents/SearchInput/SearchInput';
import { RoleEnum } from '../../../utils/authentication/authentication-types';
import { KovUserFilter } from '../../../utils/localGovernment/localGovernment-types';
import { useIsFirstRender } from '../../../utils/hooks/useIsFirstRender';
import { useTranslation } from 'react-i18next';

interface Props {
  handleFilter: (values: KovUserFilter) => void;
}

const defaultFilterValues: KovUserFilter = {
  searchTerm: '',
  roleEnum: '',
};

const UserFilter = ({ handleFilter }: Props) => {
  const { t } = useTranslation();
  const isFirst = useIsFirstRender();
  const [filterValues, setFilterValues] = useState<KovUserFilter>(defaultFilterValues);

  useEffect(() => {
    if (!isFirst) {
      handleFilter(filterValues);
    }
  }, [filterValues]);

  const handleFilterChange = (key: string, value: string) => {
    setFilterValues((prevState) => ({ ...prevState, [key as keyof KovUserFilter]: value }));
  };
  const debounceUserFilterChange = useCallback(_.debounce(handleFilterChange, 500), [filterValues]);

  return (
    <Grid container item spacing={1}>
      <Grid item>
        <SearchInput
          onSearchInputChange={(searchValue) => debounceUserFilterChange('searchTerm', searchValue)}
        />
      </Grid>
      <Grid item>
        <Paper
          sx={{
            minWidth: 245,
          }}
        >
          <Select
            size='small'
            fullWidth
            displayEmpty
            defaultValue={filterValues.roleEnum}
            onChange={(event) => handleFilterChange('roleEnum', event.target.value)}
          >
            <MenuItem value=''>{t('kov_roll-vali')}</MenuItem>
            <MenuItem value={RoleEnum.ROLE_KOV_OFFICIAL}>{t('kov_roll-ametnik')}</MenuItem>
            <MenuItem value={RoleEnum.ROLE_KOV_CADASTER_REGISTRAR}>
              {t('kov_roll-menetleja')}
            </MenuItem>
          </Select>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserFilter;
