import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, CircularProgress, Grid } from '@mui/material';
import { StyledEngineProvider, ThemeProvider, Theme } from '@mui/material/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './muiTheme';
import './index.css';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
          <CircularProgress />
        </Grid>
      }
    >
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
