import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authenticationReducer from './slices/authenticationSlice';
import permissionsReducer from './slices/permissionsSlice';
import notificationReducer from './slices/notificationsSlice';

const persistConfig = {
  key: 'root',
  storage,
};
const appReducer = combineReducers({
  authentication: authenticationReducer,
  permissions: permissionsReducer,
  notifications: notificationReducer
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === '@clearReduxState') {
    // clear redux state upon logout
    storage.removeItem('persist:root');
    state = {} as RootState;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
