import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Role, User } from '../../../utils/permissions/permission-types';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { deleteKovUserRole } from '../../../utils/localGovernment/localGovernment-requests';
import { toast } from 'react-toastify';

interface Props {
  user: User | null;
  handleClose: (user?: User) => void;
}

const DeleteUserRoleModal = ({ user, handleClose }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const handleUserRoleDelete = async () => {
    if (!user) return;

    setLoading(true);
    const { id }: Role = user.organizationRoles[0].roles[0];
    const { identificationCode }: User = user;

    const response = await deleteKovUserRole(identificationCode, id as number);
    setLoading(false);
    if (response) {
      toast.success(t('modal_kov_kustutamine-edukas'));
      handleClose(user);
      return;
    }

    toast.error(t('modal_kov_kustutamine-error'));
  };

  return (
    <Dialog open={!!user} onClose={() => handleClose()}>
      <DialogTitle>{t('modal_kov-kustuta-pealkiri')}</DialogTitle>
      <DialogContent>
        <Typography>{t('modal_kov_kustuta-kinnitus')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color='error' variant='contained' onClick={() => handleClose()}>
          {t('modal_nupp_kov_loobu')}
        </Button>
        <LoadingButton variant='contained' loading={loading} onClick={handleUserRoleDelete}>
          {t('modal_nupp_kov_kustuta')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserRoleModal;
