import React, { useEffect, useState } from 'react';
import useSharedStyles from '../../shared.styles';
import { Button, Grid } from '@mui/material';
import UserFilter from '../UserFilter/UserFilter';
import UserTable from '../UserTable/UserTable';
import {
  filterKovUserList,
  getKovUserList,
} from '../../../utils/localGovernment/localGovernment-requests';
import { User } from '../../../utils/permissions/permission-types';
import UserEditModal from '../UserEditModal/UserEditModal';
import AddNewUserModal from '../AddNewUserModal/AddNewUserModal';
import { KovUserFilter } from '../../../utils/localGovernment/localGovernment-types';
import { useTranslation } from 'react-i18next';
import DeleteUserRoleModal from '../DeleteUserRoleModal/DeleteUserRoleModal';
import { useAppSelector } from '../../../redux/reduxHooks';
import { getIsRepresentative } from '../../../redux/selectors/userPermissionsSelectors';
import { useHistory } from 'react-router-dom';

const LocalGovernmentContainer = () => {
  const { t } = useTranslation();
  const sharedClasses = useSharedStyles();
  const isKovRepresentative = useAppSelector((state) => getIsRepresentative(state));
  const history = useHistory();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectUserToDelete, setSelectUserToDelete] = useState<User | null>(null);
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isKovRepresentative) {
      history.push('/');
    } else {
      getUsers();
    }
  }, []);

  const getUsers = async () => {
    setUsers(await getKovUserList());
  };

  const handleFilter = async (filterValues: KovUserFilter) => {
    const response = await filterKovUserList(filterValues);
    setUsers(response);
  };

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
  };

  const handleUserSelectToDelete = (user: User) => {
    setSelectUserToDelete(user);
  };

  const handleEditModalClose = (updatedUser?: User) => {
    if (updatedUser) {
      const filteredUsers = users.filter(
        (user) => user.identificationCode !== updatedUser.identificationCode
      );
      setUsers([updatedUser, ...filteredUsers]);
    }
    setSelectedUser(null);
  };

  const handleNewUserModalToggle = (newUserRole?: User) => {
    if (newUserRole) {
      const filteredUsers = users.filter(
        (user) => user.identificationCode !== newUserRole.identificationCode
      );
      setUsers([newUserRole, ...filteredUsers]);
    }
    setIsNewUserModalOpen(!isNewUserModalOpen);
  };

  const handleDeleteUserModal = (deletedUser?: User) => {
    if (deletedUser) {
      setUsers(users.filter((user) => user.identificationCode !== deletedUser.identificationCode));
    }

    setSelectUserToDelete(null);
  };

  return (
    <>
      <UserEditModal user={selectedUser} handleClose={handleEditModalClose} />
      <AddNewUserModal open={isNewUserModalOpen} onClose={handleNewUserModalToggle} />
      <DeleteUserRoleModal user={selectUserToDelete} handleClose={handleDeleteUserModal} />
      <div className={sharedClasses.tableContainer}>
        <Grid container>
          <Grid item xs={12} md={9}>
            <UserFilter handleFilter={handleFilter} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant='contained'
              onClick={() => handleNewUserModalToggle()}
              style={{
                marginLeft: 'auto',
                display: 'block',
              }}
            >
              {t('kov_roll-lisa_uus')}
            </Button>
          </Grid>
        </Grid>
        <UserTable
          users={users}
          selectUser={handleUserSelect}
          selectUserToDelete={handleUserSelectToDelete}
        />
      </div>
    </>
  );
};

export default LocalGovernmentContainer;
