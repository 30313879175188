import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RoleEnum } from 'utils/authentication/authentication-types';
import { Role, OrganizationRole, User, Institution } from 'utils/permissions/permission-types';
import {
  setRoleList,
  setRoleListEnums,
  setUserList,
  setSelectedUser,
  setSelectedInstitution,
  setInstitutionList,
  setPermissionsTableLoading,
  setCurrentlyOpenedInstitution,
  setEditUserMode,
  setAddNewInstitution,
  setEditInstitutionMode,
  setIsAddRoleOpen,
  addNewUser,
  modifyRole,
  modifyEmail,
  addNewInstitution,
  modifyInstitution,
} from 'redux/actions/permissionsActions';

export interface PermissionsState {
  isTableLoading: boolean;
  editUserMode: boolean;
  editInstitutionMode: boolean;
  isAddRoleOpen: boolean;
  roleList: Role[];
  roleListEnums: RoleEnum[];
  userList: User[];
  institutionList: Institution[];
  selectedUser: User;
  selectedInstitution: Institution;
  currentlyOpenedInstitution: string;
}

const initialState: PermissionsState = {
  isTableLoading: false,
  editUserMode: false,
  isAddRoleOpen: false,
  editInstitutionMode: false,
  roleList: [],
  roleListEnums: [],
  userList: [],
  institutionList: [],
  selectedUser: <User>{},
  selectedInstitution: <Institution>{},
  currentlyOpenedInstitution: '',
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: {
    [setRoleList.toString()]: (state, { payload }) => {
      state.roleList = payload;
    },
    [setRoleListEnums.toString()]: (state, { payload }) => {
      state.roleListEnums = payload;
    },
    [setUserList.toString()]: (state, { payload }) => {
      state.userList = payload;
    },
    [setInstitutionList.toString()]: (state, { payload }) => {
      state.institutionList = payload;
    },
    [setAddNewInstitution.toString()]: (state, { payload }) => {
      state.institutionList.unshift(payload);
    },
    [setSelectedUser.toString()]: (state, { payload }) => {
      state.selectedUser = payload;
    },
    [setEditUserMode.toString()]: (state, { payload }) => {
      state.editUserMode = payload;
    },
    [setEditInstitutionMode.toString()]: (state, { payload }) => {
      state.editInstitutionMode = payload;
    },
    [setIsAddRoleOpen.toString()]: (state, { payload }) => {
      state.isAddRoleOpen = payload;
    },

    [setSelectedInstitution.toString()]: (state, { payload }) => {
      state.selectedInstitution = payload;
    },
    [addNewUser.toString()]: (state, { payload }) => {
      state.userList.unshift(payload);
    },
    [modifyInstitution.toString()]: (state, { payload }) => {
      const index = state.institutionList.findIndex(({ id }) => id === payload.id);
      state.institutionList[index] = payload;
    },
    [modifyRole.toString()]: (state, { payload }) => {
      const { responseRole, selectedUser, currentlyOpenedInstitutionIndex, type } = payload;
      const selectedUserIndex = state.userList.findIndex(
        (user) => user.identificationCode === selectedUser.identificationCode
      );

      if (currentlyOpenedInstitutionIndex === -1) {
        // general permissions
        const clonedGeneralRoles = _.cloneDeep(selectedUser.generalRoles);
        if (type === 'add') {
          clonedGeneralRoles.push(responseRole);
        }
        if (type === 'update') {
          const activeRoleIndex = clonedGeneralRoles.findIndex(
            (role: Role) => role.id === responseRole.id
          );
          clonedGeneralRoles[activeRoleIndex] = responseRole;
        }
        state.userList[selectedUserIndex].generalRoles = clonedGeneralRoles;
        state.selectedUser.generalRoles = clonedGeneralRoles;
        return;
      }

      // institution permissions
      const clonedInstitutionRoles = _.cloneDeep(selectedUser.organizationRoles);
      if (type === 'add') {
        clonedInstitutionRoles[currentlyOpenedInstitutionIndex].roles.push(responseRole);
      }
      if (type === 'update') {
        const activeRoleIndex = clonedInstitutionRoles[
          currentlyOpenedInstitutionIndex
        ].roles.findIndex((role: Role) => role.id === responseRole.id);

        clonedInstitutionRoles[currentlyOpenedInstitutionIndex].roles[activeRoleIndex] =
          responseRole;
      }
      state.userList[selectedUserIndex].organizationRoles = clonedInstitutionRoles;
      state.selectedUser.organizationRoles = clonedInstitutionRoles;
    },
    [addNewInstitution.toString()]: (state, { payload }) => {
      const { responseRole, selectedUser } = payload;
      const selectedUserIndex = state.userList.findIndex(
        (user) => user.identificationCode === selectedUser.identificationCode
      );
      const clonedInstitutionList: OrganizationRole[] = _.cloneDeep(
        state.userList[selectedUserIndex].organizationRoles
      );
      clonedInstitutionList.push({
        organizationName: responseRole.organizationName,
        organizationCode: responseRole.organizationCode,
        roles: [responseRole],
      });
      state.userList[selectedUserIndex].organizationRoles = clonedInstitutionList;
      state.selectedUser.organizationRoles = clonedInstitutionList;
    },
    [modifyEmail.toString()]: (state, { payload }) => {
      const selectedUserIndex = state.userList.findIndex((user) => user.id === payload.id);
      state.userList[selectedUserIndex].email = payload.email;
    },
    [setPermissionsTableLoading.toString()]: (state, { payload }) => {
      state.isTableLoading = payload;
    },
    [setPermissionsTableLoading.toString()]: (state, { payload }) => {
      state.isTableLoading = payload;
    },
    [setCurrentlyOpenedInstitution.toString()]: (state, { payload }) => {
      state.currentlyOpenedInstitution = payload;
    },
  },
});

export default permissionsSlice.reducer;
