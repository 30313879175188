import React from 'react';
import Search from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper } from '@mui/material';
import useStyles from './SearchInput.styles';

interface Props {
  onSearchInputChange?: (searchValue: string) => void;
}

const SearchInput = ({ onSearchInputChange }: Props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.searchField}>
      <IconButton
        color='primary'
        aria-label='search'
        className={classes.searchInputIcon}
        size='large'
      >
        <Search />
      </IconButton>
      <InputBase
        onChange={(event) =>
          onSearchInputChange && onSearchInputChange((event.target as HTMLInputElement).value)
        }
        className={classes.searchInput}
        placeholder='Otsi nime või koodi järgi'
        inputProps={{ 'aria-label': 'search for observations' }}
      />
    </Paper>
  );
};

export default SearchInput;
