import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import StylesProvider from '@mui/styles/StylesProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { et } from 'date-fns/locale';
import { MapProvider } from 'katri-map';
import { store } from './redux/store';
import MainContainer from './components/MainContainer/MainContainer';
import './i18n/i18n-config';
import '@fontsource/roboto';
import 'react-toastify/dist/ReactToastify.css';
import './styles.module.scss';
import './App.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import etLocale from 'date-fns/locale/et';
const App = () => {
  return (
    <StylesProvider injectFirst>
      <Provider store={store}>
        <MapProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={etLocale}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={et}>
              <Router>
                <MainContainer />
              </Router>
            </MuiPickersUtilsProvider>
          </LocalizationProvider>
        </MapProvider>
      </Provider>
    </StylesProvider>
  );
};

export default App;
