import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { CADASTRE_BE_URL } from 'configs/url-config';

interface GenericObject {
  [key: string]: unknown;
}

export default i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    backend: {
      loadPath: `${CADASTRE_BE_URL}api/classifiers`,
      parse: (data, lng) => {
        const translation = {} as GenericObject;
        const items: [] = JSON.parse(data).message;
        items.forEach(({ key, valueET, valueEN, valueRU }) => {
          return (translation[key as keyof GenericObject] =
            lng === 'et' ? valueET : lng === 'en' ? valueEN : valueRU);
        });
        return translation;
      },
    },
    detection: {
      order: ['cookie', 'localStorage'],
      caches: ['cookie'],
    },
    fallbackLng: 'et',
    interpolation: {
      escapeValue: false,
    },
  });
