import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  searchField: {
    display: 'flex',
    width: '100%',
    minWidth: 245,
  },
  searchInput: {
    width: '100%',
    padding: 4,
  },
  searchInputIcon: {
    padding: 8,
  },
}));

export default useStyles;
