import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'styles.module.scss';

const useSharedStyles = makeStyles((theme: Theme) => ({
  verticalScrollbar: {
    '&::-webkit-scrollbar': {
      width: '1.6em',
    },
    '&::-webkit-scrollbar-thumb:vertical': {
      border: '0.5em solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box',
      '-webkit-border-radius': '1em',
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      '-webkit-box-shadow': 'inset 0 0 0 1px rgba(0, 0, 0, 0.025)',
    },
    '&::-webkit-scrollbar-corner': {
      display: 'none',
    },
  },
  collapseHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: 8,
  },
  collapseWrapper: {
    height: '100%',
  },
  collapseWrapperInner: {
    width: '100%',
    display: 'flex',
    height: '100%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  tableContainer: {
    height: '100%',
    paddingLeft: 64,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: styles.tableBackgroundGrey,
    padding: 24,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 16,
    },
  },
  tableColumnHeader: {
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },
  },
}));

export default useSharedStyles;
