import React, { useState } from 'react';
import { format } from 'date-fns';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DatePicker, LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import { RoleEnum } from '../../../utils/authentication/authentication-types';
import { addNewKovUser } from '../../../utils/localGovernment/localGovernment-requests';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { KovRoleValues } from '../../../utils/localGovernment/localGovernment-types';
import { User } from '../../../utils/permissions/permission-types';

interface Props {
  open: boolean;
  onClose: (user?: User) => void;
}

const defaultNewUser: KovRoleValues = {
  identificationCode: null,
  role: null,
  startDate: format(new Date(), 'yyyy-MM-dd'),
  endDate: null,
  comment: null,
};

const AddNewUserModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<KovRoleValues>(defaultNewUser);

  const handleValuesChange = (key: string, value: number | RoleEnum | string | null) => {
    setNewUser((prevState) => ({ ...prevState, [key as keyof KovRoleValues]: value }));
  };

  const addNewUserRole = async () => {
    if (!newUser?.identificationCode || isNaN(newUser?.identificationCode)) {
      toast.error(t('error_kov_uus_roll_modal_isikukood-vale'));
      return;
    }

    if (!newUser.role) {
      toast.error(t('error_kov_uus_roll_modal_vali-roll'));
      return;
    }

    if (!newUser.startDate) {
      toast.error(t('error_kov_uus_roll_modal_vali-kuupaevad'));
      return;
    }

    setLoading(true);
    const response = await addNewKovUser(newUser);
    setLoading(false);
    if (response) {
      toast.success(t('modal_kov_kasutaja-roll-lisatud'));
      setNewUser(defaultNewUser);
      onClose(response);
      return;
    }

    toast.error(t('modal_kov_kasutaja-roll-viga'));
  };

  const onModalClose = (event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setNewUser(defaultNewUser);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onModalClose}>
      <DialogTitle
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <>
          {t('modal_kov-lisa-pealkiri')}
          <IconButton onClick={onModalClose}>
            <Close />
          </IconButton>
        </>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item>
            <Grid item xs={4}>
              {t('silt_modal_kov_isikukood')}
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                size='small'
                onChange={(event) =>
                  handleValuesChange('identificationCode', Number(event.target.value))
                }
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={4}>
              {t('silt_modal_kov_roll')}
            </Grid>
            <Grid item xs={8}>
              <Select
                fullWidth
                onChange={(event) => handleValuesChange('role', event.target.value as RoleEnum)}
                size='small'
                placeholder='Vali roll'
              >
                <MenuItem value={RoleEnum.ROLE_KOV_CADASTER_REGISTRAR}>
                  {t('kov_roll-menetleja')}
                </MenuItem>
                <MenuItem value={RoleEnum.ROLE_KOV_OFFICIAL}>{t('kov_roll-ametnik')}</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={4}>
              {t('modal_kov_silt-kehtivuse-algus')}
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                mask='__.__.____'
                allowSameDateSelection
                minDate={new Date()}
                value={newUser.startDate}
                onChange={(value) => {
                  if (value) {
                    handleValuesChange('startDate', format(new Date(value), 'yyyy-MM-dd'));
                  } else {
                    handleValuesChange('startDate', null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size='small'
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={4}>
              {t('modal_kov_silt-kehtivuse-lopp')}
            </Grid>
            <Grid item xs={8}>
              <DatePicker
                allowSameDateSelection
                mask='__.__.____'
                value={newUser.endDate}
                onChange={(value) => {
                  if (value) {
                    handleValuesChange('endDate', format(new Date(value), 'yyyy-MM-dd'));
                  } else {
                    handleValuesChange('endDate', null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size='small'
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={4}>
              {t('modal_muuda_kov-roll-kommentaar')}
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                multiline
                minRows={4}
                size='small'
                onChange={(e) => handleValuesChange('comment', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='contained' loading={loading} onClick={addNewUserRole}>
          {t('modal_kov_nupp-lisa')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewUserModal;
