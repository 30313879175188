import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  DataGridForcedPropsKey,
  DataGridPropsWithComplexDefaultValueBeforeProcessing,
  DataGridPropsWithDefaultValues,
  DataGridPropsWithoutDefaultValue,
} from '@mui/x-data-grid/models/props/DataGridProps';
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination/TablePagination';

const TranslatedDataGrid = (
  defaultProps: Omit<
    Partial<DataGridPropsWithDefaultValues> &
      DataGridPropsWithComplexDefaultValueBeforeProcessing &
      DataGridPropsWithoutDefaultValue,
    DataGridForcedPropsKey
  >
) => {
  const { t } = useTranslation();
  return (
    <DataGrid
      {...defaultProps}
      localeText={{
        noRowsLabel: `${t('andmedPuuduvadTabelis')}`,
        errorOverlayDefaultLabel: `${t('vigaTabelis')}`,
        toolbarExport: `${t('ekspordiTabel')}`,
        toolbarExportCSV: `${t('laadiTabelCsvna')}`,
      }}
      componentsProps={{
        pagination: {
          labelRowsPerPage: `${t('riduLehel')}`,
          labelDisplayedRows: (paginationInfo: LabelDisplayedRowsArgs) =>
            `${t('näidatavadReadTabelis')} ${paginationInfo.from}-${paginationInfo.to}, ${t(
              'riduKokkuTabelis'
            )} ${paginationInfo.count}`,
        },
      }}
    />
  );
};

export default TranslatedDataGrid;
