import { baseRequest } from 'utils/baseRequest';
import { ActiveUser } from './authentication-types';
import { NOTIFICATIONS_BE_URL } from '../../configs/url-config';
import { Role } from '../permissions/permission-types';

const getTargetUrl = async (): Promise<string | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'GET',
      endpoint: 'api/authentication/get-target-url',
    });
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

const getAuthCode = async (
  code: string,
  redirectUrl: string,
  loginInitiatedFrom: string | null
): Promise<ActiveUser | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'POST',
      endpoint: 'api/authentication/exchange-auth-code',
      data: {
        code,
        redirectUrl,
        loginInitiatedFrom,
      },
    });
    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

const chooseRole = async (body: Role[]): Promise<ActiveUser | undefined> => {
  try {
    const { status, data } = await baseRequest({
      method: 'POST',
      endpoint: 'api/authentication/choose-role',
      data: body,
    });
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

const getPing = async (): Promise<number | undefined> => {
  try {
    const { status, data } = await baseRequest({
      method: 'get',
      endpoint: 'api/authentication/ping',
    });
    if (status === 200) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
};

const refreshSession = async (): Promise<number | undefined> => {
  try {
    const { status } = await baseRequest({
      method: 'get',
      endpoint: 'api/authentication/refresh-session',
    });
    if (status === 200) {
      return status;
    }
  } catch (error) {
    console.error(error);
  }
};

const logOut = async (): Promise<number | undefined> => {
  try {
    const { status } = await baseRequest({
      method: 'GET',
      endpoint: 'api/authentication/log-out',
    });
    if (status) {
      return status;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

const getNrOfUnseen = async (): Promise<number> => {
  try {
    const { data } = await baseRequest({
      method: 'GET',
      endpoint: 'api/notifications/unseen-number',
      baseUrl: NOTIFICATIONS_BE_URL,
    });
    if (data) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return 0;
};

export { getTargetUrl, getAuthCode, chooseRole, logOut, getPing, refreshSession, getNrOfUnseen };
