import { baseRequest } from '../baseRequest';
import { KovRoleValues, KovUserFilter } from './localGovernment-types';
import { User } from '../permissions/permission-types';

export const getKovUserList = async (): Promise<User[]> => {
  try {
    const { data } = await baseRequest({
      method: 'GET',
      endpoint: 'api/person/kov',
    });
    if (Array.isArray(data?.message)) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};

export const filterKovUserList = async (body: KovUserFilter): Promise<User[]> => {
  try {
    const {
      data: { message, status },
    } = await baseRequest({
      method: 'POST',
      endpoint: 'api/person/kov/search/filter',
      data: body,
    });
    if (status === 'OK') {
      return message;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};

export const addNewKovUser = async (user: KovRoleValues): Promise<User | undefined> => {
  const { identificationCode, role, startDate, endDate, comment } = user;

  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'POST',
      endpoint: `api/person/roles/kov/add/${identificationCode}`,
      data: { role, startDate, endDate, comment },
    });

    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
};

export const updateKovUser = async (user: KovRoleValues): Promise<User | undefined> => {
  const { id, identificationCode, role, startDate, endDate, comment } = user;

  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'POST',
      endpoint: `api/person/roles/kov/update/${identificationCode}`,
      data: { id, role, startDate, endDate, comment },
    });

    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
};

export const deleteKovUserRole = async (identificationCode: number, roleId: number) => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'POST',
      endpoint: `api/person/roles/kov/delete/${identificationCode}/${roleId}`,
    });

    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
};
