import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UnseenNotifications {
  nrOfUnseen: number;
  hasUnseen: boolean;
}

const initialState: UnseenNotifications = {
  nrOfUnseen: 0,
  hasUnseen: false
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNrOfUnseen: (state, action: PayloadAction<number>) => {
      state.nrOfUnseen = action.payload;
    },
    setHasUnseen: (state, action: PayloadAction<boolean>) => {
      state.hasUnseen = action.payload;
    },
  },
});

export const { setNrOfUnseen, setHasUnseen } = notificationSlice.actions;

export default notificationSlice.reducer;
