import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveUser, AuthenticationState } from 'utils/authentication/authentication-types';

const initialState: AuthenticationState = {
  activeUser: <ActiveUser>{},
  isAuthLoading: true,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<{ [key: string]: unknown }>) => {
      state.activeUser = {
        ...state.activeUser,
        ...action.payload,
      };
    },
    setIsAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.isAuthLoading = action.payload;
    },
    resetUserData: () => initialState,
  },
});

export const { setUserData, resetUserData, setIsAuthLoading } = authenticationSlice.actions;

export default authenticationSlice.reducer;
