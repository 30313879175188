import { createAction } from '@reduxjs/toolkit';

export const setRoleList = createAction('@setRoleList', (roleList) => {
  return { payload: roleList };
});
export const setRoleListEnums = createAction('@setRoleListEnums', (roleListEnums) => {
  return { payload: roleListEnums };
});
export const setUserList = createAction('@setUserList', (userList) => {
  return { payload: userList };
});
export const setInstitutionList = createAction('@setInstitutionList', (institutionList) => {
  return { payload: institutionList };
});
export const setAddNewInstitution = createAction('@setAddNewInstitution', (payload) => ({
  payload,
}));
export const setSelectedUser = createAction('@setSelectedUser', (selectedUser) => {
  return { payload: selectedUser };
});
export const setIsAddRoleOpen = createAction('@setIsAddRoleOpen', (boolean) => {
  return { payload: boolean };
});
export const setSelectedInstitution = createAction(
  '@setSelectedInstitution',
  (selectedInstitution) => {
    return { payload: selectedInstitution };
  }
);
export const setCurrentlyOpenedInstitution = createAction(
  '@setCurrentlyOpenedInstitution',
  (institution) => {
    return { payload: institution };
  }
);
export const clearInputFields = createAction('@clearInputFields', (payload) => ({ payload }));
export const addNewInstitution = createAction('@addNewInstitution', (payload) => ({
  payload,
}));
export const modifyRole = createAction('@modifyRole', (payload) => ({
  payload,
}));
export const modifyInstitution = createAction('@modifyInstitution', (payload) => ({
  payload,
}));
export const modifyEmail = createAction('@modifyEmail', (email) => {
  return { payload: email };
});
export const setEditUserMode = createAction('@setEditUserMode', (boolean) => {
  return { payload: boolean };
});
export const setEditInstitutionMode = createAction('@setEditInstitutionMode', (boolean) => {
  return { payload: boolean };
});
export const addNewUser = createAction('@addNewUser', (newUser) => {
  return { payload: newUser };
});
export const setPermissionsTableLoading = createAction('@setPermissionsTableLoading', (boolean) => {
  return { payload: boolean };
});
