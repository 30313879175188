import { baseRequest } from 'utils/baseRequest';
import { Role, NewUser, User, Institution } from 'utils/permissions/permission-types';
import { DataManipulation, PageableData } from '../../types/types';

const getUserList = async (dataManipulation: DataManipulation): Promise<PageableData<User> | null> => {
  try {
    const { data } = await baseRequest({
      method: 'POST',
      endpoint: 'api/person/all',
      data: dataManipulation
    });
      return data.message;
  } catch (error) {
    console.error(error);
  }
  return null;
};

const getRoleEnums = async (): Promise<[]> => {
  try {
    const { data } = await baseRequest({
      method: 'GET',
      endpoint: 'api/person/roles/enums',
    });
    if (Array.isArray(data?.message)) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};

const getRoleList = async (identificationCode: number): Promise<[]> => {
  try {
    const { data } = await baseRequest({
      method: 'GET',
      endpoint: `api/person/roles/user/${identificationCode}`,
    });
    if (Array.isArray(data?.message)) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};

const getInstitutionList = async (): Promise<[]> => {
  try {
    const { data } = await baseRequest({
      method: 'GET',
      endpoint: 'api/organization',
    });
    if (Array.isArray(data?.message)) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};

const postUserData = async (content: NewUser): Promise<Record<string, unknown> | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'post',
      endpoint: 'api/person',
      data: { ...content },
    });
    if (data.message) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

const postUpdateUserData = async (
  id: string,
  content: User
): Promise<Record<string, unknown> | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'post',
      endpoint: 'api/person/update',
      data: { ...content },
    });
    if (data.message) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

const postAddRole = async (
  identificationCode: number,
  content: Role
): Promise<Record<string, unknown> | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'post',
      endpoint: `api/person/roles/add/${identificationCode}`,
      data: { ...content },
    });
    if (data.message) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};
const postUpdateRole = async (
  identificationCode: number,
  content: Role
): Promise<Record<string, unknown> | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'post',
      endpoint: `api/person/roles/update/${identificationCode}`,
      data: { ...content },
    });
    if (data.message) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};
const getInstitutionFromRegistry = async (
  organizationCode: string
): Promise<Institution | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'get',
      endpoint: `api/organization/search/registry/organizationCode/${organizationCode}`,
    });
    if (data.message) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

const postNewInstitution = async (content: Institution): Promise<Institution | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'post',
      endpoint: 'api/organization',
      data: { ...content },
    });
    if (data.message) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

const postUpdateInstitution = async (
  id: string,
  authority: string
): Promise<Institution | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'post',
      endpoint: `api/organization/update/${id}`,
      data: {
        authority,
      },
    });
    if (data.message) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};
const getFilteredInstitutionList = async (searchTerm: string): Promise<Institution[] | []> => {
  try {
    const { data } = await baseRequest({
      method: 'get',
      endpoint: `api/organization/search/filter/${searchTerm}`,
    });
    if (data.message) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};
const getFilteredUserList = async (searchTerm: string): Promise<User[] | []> => {
  try {
    const { data } = await baseRequest({
      method: 'get',
      endpoint: `api/person/search/filter/${searchTerm}`,
    });
    if (data.message) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return [];
};

const getMenuItemPermission = async (unleashName: string): Promise<boolean> => {
  try {
    const { data } = await baseRequest({
      method: 'GET',
      endpoint: `api/unleash/${unleashName}`,
    });

    if (data) {
      return data;
    }
  } catch (e) {
    console.error(e);
  }
  return false;
};

export {
  getUserList,
  getRoleEnums,
  getRoleList,
  getInstitutionList,
  postUserData,
  postAddRole,
  postUpdateRole,
  postUpdateUserData,
  postUpdateInstitution,
  getInstitutionFromRegistry,
  postNewInstitution,
  getFilteredInstitutionList,
  getFilteredUserList,
  getMenuItemPermission,
};
