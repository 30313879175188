import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Role, User } from '../../../utils/permissions/permission-types';
import { DatePicker, LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import { RoleEnum } from '../../../utils/authentication/authentication-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { updateKovUser } from '../../../utils/localGovernment/localGovernment-requests';
import { KovRoleValues } from '../../../utils/localGovernment/localGovernment-types';

interface Props {
  user: User | null;
  handleClose: (user?: User) => void;
}

interface NewRole {
  id?: number;
  role: RoleEnum;
  startDate: string | null;
  endDate: string | null;
  comment: string | null;
}

const defaultValues: NewRole = {
  role: RoleEnum.ROLE_KOV_CADASTER_REGISTRAR,
  startDate: null,
  endDate: null,
  comment: null,
};

const UserEditModal = ({ user, handleClose }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [newRoleValues, setNewRoleValues] = useState<NewRole>(defaultValues);

  useEffect(() => {
    if (user) {
      const { id, role, startDate, endDate, comment }: Role = user.organizationRoles[0].roles[0];

      setNewRoleValues({
        id,
        role: role as RoleEnum,
        startDate: startDate || null,
        endDate: endDate || null,
        comment: comment || null,
      });
    }
  }, [user]);

  const handleValueChange = (key: string, value: RoleEnum | string | null) => {
    setNewRoleValues((prevState) => ({ ...prevState, [key as keyof NewRole]: value }));
  };

  const handleEditUser = async () => {
    if (!user) return;

    if (!newRoleValues.role) {
      toast.error(t('error_kov_uus_roll_modal_vali-roll'));
      return;
    }

    if (!newRoleValues.startDate) {
      toast.error(t('error_kov_uus_roll_modal_vali-kuupaevad'));
      return;
    }

    const editPayload: KovRoleValues = {
      identificationCode: user.identificationCode,
      ...newRoleValues,
    };

    setLoading(true);
    const response = await updateKovUser(editPayload);
    if (response) {
      toast.success(t('modal_kov_muutmine-edukas'));
      handleClose(response);
      return;
    }

    toast.error(t('modal_kov_muutmisel-esines-viga'));
    setLoading(false);
  };

  const onModalClose = (event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    handleClose();
  };

  return (
    <Dialog open={!!user} onClose={onModalClose}>
      <DialogTitle
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <>
          {t('modal_kov-muuda-pealkiri')}
          <IconButton onClick={onModalClose}>
            <Close />
          </IconButton>
        </>
      </DialogTitle>
      <DialogContent>
        {user && (
          <Grid container spacing={2}>
            <Grid container item>
              <Grid item xs={4}>
                {t('modal_kov_silt-nimi')}
              </Grid>
              <Grid item xs={8}>{`${user.firstName} ${user.lastName}`}</Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={4}>
                {t('silt_modal_kov_email')}
              </Grid>
              <Grid item xs={8}>
                {user.email}
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={4}>
                {t('silt_modal_kov_roll')}
              </Grid>
              <Grid item xs={4}>
                <Select
                  fullWidth
                  size='small'
                  onChange={(event) => handleValueChange('role', event.target.value as RoleEnum)}
                  defaultValue={newRoleValues.role}
                >
                  <MenuItem value={RoleEnum.ROLE_KOV_CADASTER_REGISTRAR}>
                    {t('kov_roll-menetleja')}
                  </MenuItem>
                  <MenuItem value={RoleEnum.ROLE_KOV_OFFICIAL}>{t('kov_roll-ametnik')}</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={4}>
                {t('modal_kov_silt-kehtivuse-algus')}
              </Grid>
              <Grid item xs={8}>
                <DatePicker
                  allowSameDateSelection
                  value={newRoleValues.startDate}
                  onChange={(value) => {
                    if (value) {
                      handleValueChange('startDate', format(new Date(value), 'yyyy-MM-dd'));
                    } else {
                      handleValueChange('startDate', null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size='small'
                      {...params}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={4}>
                {t('modal_kov_silt-kehtivuse-lopp')}
              </Grid>
              <Grid item xs={8}>
                <DatePicker
                  allowSameDateSelection
                  value={newRoleValues.endDate}
                  onChange={(value) => {
                    if (value) {
                      handleValueChange('endDate', format(new Date(value), 'yyyy-MM-dd'));
                    } else {
                      handleValueChange('endDate', null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size='small'
                      {...params}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={4}>
                {t('modal_kov_silt-kommentaar')}
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  multiline
                  minRows={4}
                  size='small'
                  defaultValue={newRoleValues.comment}
                  onChange={(e) => handleValueChange('comment', e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='contained' loading={loading} onClick={handleEditUser}>
          {t('modal_kov_nupp-muuda')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserEditModal;
