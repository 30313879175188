import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    height: '100%',
    display: 'flex',
  },
  contentContainer: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    height: '100%',
  },
  spinner: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginLeft: '-2rem',
    marginBottom: '-2rem',
  },
  divider: {
    ...theme.mixins.toolbar,
  },
}));

export default useStyles;
