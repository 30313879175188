interface GitlabWindow extends Window {
  LICENSES_BE_URL: string;
  LICENSES_FE_URL: string;
  CADASTRE_URL: string;
  CADASTRE_BE_URL: string;
  LAND_MANAGEMENT_FE_URL: string;
  NOTIFICATIONS_URL: string;
  NOTIFICATIONS_BE_URL: string;
  ADMIN_FE_URL: string;
  MKHIS_FE_URL: string;
  CONTACT_EMAIL: string;
  CONTACT_PHONE: string;
  MENETLUS_FE_URL: string;
  SURVEYOR_FE_URL: string;
  CONFIRMATIONS_URL: string;
}

declare let window: GitlabWindow;

export const LICENSES_BE_URL = window.LICENSES_BE_URL || 'https://litsentsidbedev.kataster.ee/';
export const LICENSES_FE_URL = window.LICENSES_FE_URL || 'https://litsentsiddev.kataster.ee';
export const LAND_MANAGEMENT_FE_URL =
  window.LAND_MANAGEMENT_FE_URL || 'https://maakorraldusdev.kataster.ee/';
export const CADASTRE_URL = window.CADASTRE_URL || 'https://minudev.kataster.ee/';
export const CADASTRE_BE_URL = window.CADASTRE_BE_URL || 'https://cadastrebedev.kataster.ee/';
export const GEOLOCATION_URL = 'https://geolocation-db.com/json/';
export const NOTIFICATIONS_URL = window.NOTIFICATIONS_URL || 'https://teavituseddev.kataster.ee';
export const NOTIFICATIONS_BE_URL =
  window.NOTIFICATIONS_BE_URL || 'https://notifierdev.kataster.ee';
export const ADMIN_FE_URL = window.ADMIN_FE_URL || 'https://admindev.kataster.ee/';
export const MKHIS_FE_URL = window.MKHIS_FE_URL || 'https://mkhisadmintest.kataster.ee/';
export const MENETLUS_FE_URL = window.MENETLUS_FE_URL || 'https://menetlusdev.kataster.ee/';
export const SURVEYOR_FE_URL = window.SURVEYOR_FE_URL || 'https://maamootjadev.kataster.ee/';
export const CONFIRMATIONS_URL = window.CONFIRMATIONS_URL || 'https://kooskolastusdev.kataster.ee/';
