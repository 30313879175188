import React, { useEffect, useState } from 'react';
import { Box, Fade } from '@mui/material';
import euLogo from '../../assets/eu_logo.png';

const EULogo = () => {
  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setVisible(false), 5000);
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        bottom: { xs: 80, sm: 200 },
      }}
    >
      <Fade in={visible} timeout={1000}>
        <img src={euLogo} alt='Euroopa liidu ja Struktuurifondi logo' />
      </Fade>
    </Box>
  );
};

export default EULogo;
