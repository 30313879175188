import axios, { Method, ResponseType } from 'axios';
import { CADASTRE_BE_URL } from '../configs/url-config';

const BASE_URL = CADASTRE_BE_URL;

interface RequestPayload {
  method: Method;
  endpoint: string;
  data?: any;
  baseUrl?: string;
  contentType?: string;
  responseType?: ResponseType
}

export const baseRequest = async ({
  method,
  endpoint,
  data,
  baseUrl,
  contentType,
  responseType = 'json'
}: RequestPayload) => {
  return axios({
    method,
    baseURL: baseUrl || BASE_URL,
    url: endpoint,
    headers: {
      Accept: '*/*',
      'Content-Type': contentType || 'application/json',
    },
    responseType,
    withCredentials: true,
    ...(data && { data }),
  });
};
