import React, { lazy, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Alert, Box, CircularProgress, Grid } from '@mui/material';
import { ErrorNotification, Map } from 'katri-map';
import {
  resetUserData,
  setIsAuthLoading,
  setUserData,
} from '../../redux/slices/authenticationSlice';
import { useAppSelector } from '../../redux/reduxHooks';
import {
  getAuthCode,
  getPing,
  getTargetUrl,
  logOut,
  refreshSession,
} from 'utils/authentication/authentication';
import {
  setInstitutionList,
  setPermissionsTableLoading,
  setRoleList,
  setRoleListEnums,
} from 'redux/actions/permissionsActions';
import { getIsAdministrator } from 'redux/selectors/userPermissionsSelectors';
import { RoleEnum } from 'utils/authentication/authentication-types';
import {
  getInstitutionList,
  getRoleEnums,
  getRoleList,
} from 'utils/permissions/permissions';
import { ActiveUser } from '../../utils/authentication/authentication-types';
import { CADASTRE_URL } from '../../configs/url-config';
import EULogo from '../EULogo/EULogo';
import useStyles from './MainContainer.styles';
import LocalGovernmentContainer from '../localGovernment/LocalGovernmentContainer/LocalGovernmentContainer';


const HeaderNavigationContainer = lazy(
  () => import('../header/HeaderNavigation/HeaderNavigationContainer'),
);
const ExtendSessionModal = lazy(() => import('components/ExtendSessionModal/ExtendSessionModal'));
const LicensesContainer = lazy(() => import('../licenses/LicensesContainer/LicensesContainer'));
const UserPermissionsContainer = lazy(
  () => import('components/permissions/user/UserPermissionsContainer/UserPermissionsContainer'),
);
const InstitutionPermissionsContainer = lazy(
  () =>
    import(
      'components/permissions/institution/InstitutionPermissionsContainer/InstitutionPermissionsContainer'
      ),
);
const RoleSelectionModal = lazy(() => import('../RoleSelectionModal/RoleSelectionModal'));
const RepresentativeAuthoritiesContainer = lazy(
  () =>
    import(
      '../representativeAuthorities/RepresentativeAuthoritiesContainer/RepresentativeAuthoritiesContainer'
      ),
);

const redirectUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : CADASTRE_URL;
export const generalRoleKeys = [RoleEnum.ROLE_USER, RoleEnum.ROLE_LANDOWNER];
export const institutionRoleKeys = [
  RoleEnum.ROLE_SURVEYOR,
  RoleEnum.ROLE_OFFICIAL,
  RoleEnum.ROLE_ADMIN_MASS_VALUATION,
  RoleEnum.ROLE_VALUATOR,
];

const MainContainer = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isExtendSessionModalOpen, setIsExtendSessionModalOpen] = useState(false);
  const [secondsUntilSessionEnd, setSecondsUntilSessionEnd] = useState(0);
  const { activeUser, isAuthLoading } = useAppSelector((state) => state.authentication);
  const isAdministrator = useAppSelector((state) => getIsAdministrator(state));
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(setIsAuthLoading(false));
    if (activeUser.identificationCode) {
      getPing();
    }
  }, []);

  // todo see oleks mõistlik kuskile komponenti tõsta
  useEffect(() => {
    const { pathname, search } = location;
    if (
      (pathname === '/user-permissions' || pathname === '/institution-permissions') &&
      isAdministrator
    ) {
      dispatch(setPermissionsTableLoading(true));
      batch(async () => {
        dispatch(setInstitutionList(await getInstitutionList()));
      });
      dispatch(setPermissionsTableLoading(false));
    }

    if (search === '?logout=true') {
      handleLogOut();
    }
  }, [location, isAdministrator]);

  useEffect(() => {
    const { identificationCode } = activeUser;
    if (identificationCode) {
      (async () => {
        const roleList = await getRoleList(identificationCode);
        const roleEnums = await getRoleEnums();
        batch(async () => {
          dispatch(setRoleList(roleList));
          dispatch(setRoleListEnums(roleEnums));
        });
      })();
      const interval = setInterval(async () => {
        const tokenRemainingSeconds = await getPing();
        if (tokenRemainingSeconds && tokenRemainingSeconds < 300) {
          setSecondsUntilSessionEnd(tokenRemainingSeconds);
          setIsExtendSessionModalOpen(true);
        }
      }, 180000);

      return () => clearInterval(interval);
    }
  }, [activeUser.identificationCode]);

  useEffect(() => {
    const params = new URLSearchParams(history?.location.search);
    const code = params.get('code');
    const loginFrom = params.get('from');
    if (code) {
      (async () => {
        const loginInitiatedFrom = localStorage.getItem('loginInitiatedFrom');
        const loginFromExpires = localStorage.getItem('loginFromExpires');
        const data = await getAuthCode(code, redirectUrl, loginInitiatedFrom);
        if (data) {
          const currentDate = new Date().toISOString();
          setCurrentUserData(data);
          history.push(localStorage.getItem('originUrl') || '/');
          localStorage.removeItem('originUrl');
          if (
            data.roles.length === 1 &&
            loginInitiatedFrom &&
            loginFromExpires &&
            loginFromExpires > currentDate
          ) {
            window.location.href = loginInitiatedFrom;
            localStorage.removeItem('loginInitiatedFrom');
          }
        }
      })();
    }
    if (loginFrom) {
      handleLogin(loginFrom);
    }
    dispatch(setIsAuthLoading(false));
  }, [history]);

  useEffect(() => {
    if (secondsUntilSessionEnd > 0) {
      const timeout = setTimeout(() => {
        setSecondsUntilSessionEnd(secondsUntilSessionEnd - 1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
    if (isExtendSessionModalOpen && secondsUntilSessionEnd < 1) {
      setIsExtendSessionModalOpen(false);
      handleLogOut();
    }
  }, [secondsUntilSessionEnd, isExtendSessionModalOpen]);

  const setCurrentUserData = (data: ActiveUser) => {
    const { firstName, lastName, identificationCode, roles, companies } = data;
    dispatch(
      setUserData({
        firstName,
        lastName,
        identificationCode,
        roles,
        allRoles: roles,
        companies,
      }),
    );
  };

  const handleLogin = async (loginFrom?: string) => {
    if (loginFrom) {
      const urlCheckRegex = /^(http|https):\/\/\w+\.kataster\.ee.*$/;
      if (urlCheckRegex.test(loginFrom)) {
        const currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() + 30);
        localStorage.setItem('loginInitiatedFrom', loginFrom);
        localStorage.setItem('loginFromExpires', currentDate.toISOString());
      }
    }
    localStorage.setItem('originUrl', location.pathname);
    const url = await getTargetUrl();
    if (url?.length) {
      dispatch(setIsAuthLoading(true));
      window.location.href = url.replace('redirect_uri=', `redirect_uri=${redirectUrl}`);
    }
  };

  const handleLogOut = async () => {
    dispatch(setIsAuthLoading(true));
    const status = await logOut();
    if (status) {
      dispatch(resetUserData());
      history.push('/');
      dispatch(setIsAuthLoading(false));
    }
  };

  const handleExtendSession = async () => {
    const successfulExtension = await refreshSession();
    if (successfulExtension) {
      setIsExtendSessionModalOpen(false);
      setSecondsUntilSessionEnd(0);
      toast.success(t('sessioonPikendatud'));
    } else {
      toast.error(t('sessiooniPikendamineEbaõnnestus'));
      handleLogOut();
    }
  };

  const langSelection = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const handleChangeRole = () => {
    dispatch(setUserData({ roles: activeUser.allRoles }));
  };

  return isAuthLoading ? (
    <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
      <CircularProgress />
    </Grid>
  ) : (
    <div className={classes.pageContainer}>
      <ErrorNotification
        axiosInstance={axios}
        onSessionEnded={handleLogOut}
        toastInstance={toast}
        ToastContainer={ToastContainer}
      />
      <HeaderNavigationContainer
        onLogin={() => handleLogin()}
        onLogOut={handleLogOut}
        onLangSelect={langSelection}
        onChangeRole={handleChangeRole}
      />
      <main className={classes.contentContainer}>
        <div className={classes.divider} />
        {i18n.exists('hoiatus_minu-kataster_banner') ? (
          <Box sx={{ backgroundColor: 'rgb(229, 246, 253)', paddingLeft: '40px', display: { xs: 'none', sm: 'initial' } }}>
            <Alert severity='info'>{t('hoiatus_minu-kataster_banner')}</Alert>
          </Box>
        ) : null}
        <div className={classes.content}>
          <ExtendSessionModal
            isOpen={isExtendSessionModalOpen}
            onClose={(event, reason) => {
              if (reason === 'backdropClick') {
                return;
              }
              setIsExtendSessionModalOpen(false);
            }}
            secondsUntilSessionEnd={secondsUntilSessionEnd}
            onLogout={handleLogOut}
            onExtendSession={handleExtendSession}
          />
          <RoleSelectionModal />
          <Switch>
            <Route exact path={['/', '/cadastre/:id', '/properties']}>
              <>
                <Map language={i18n.language} userData={activeUser} />
                <EULogo />
              </>
            </Route>
            <Route path='/licenses'>
              <LicensesContainer onLogin={() => handleLogin()} />
            </Route>
            <Route path='/representative-authorities'>
              <RepresentativeAuthoritiesContainer />
            </Route>
            <Route path='/local-government-users'>
              <LocalGovernmentContainer />
            </Route>
            <Route path='/user-permissions'>
              <UserPermissionsContainer />
            </Route>
            <Route path='/institution-permissions'>
              <InstitutionPermissionsContainer />
            </Route>
          </Switch>
        </div>
      </main>
    </div>
  );
};
export default MainContainer;
