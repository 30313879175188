import { createTheme } from '@mui/material/styles';
import { LabelDisplayedRowsArgs } from '@mui/material';
import styles from './styles.module.scss';


const theme = createTheme({
  typography: {
    fontSize: 14,
  },
  palette: {
    primary: {
      main: styles.primaryBlue,
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: styles.black,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: styles.primaryBlue,
          borderRadius: '5px',
          padding: '8px 24px',
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTablePagination: {
      defaultProps: {
        labelRowsPerPage: 'Ridu ühel lehel:',
        labelDisplayedRows: (paginationInfo: LabelDisplayedRowsArgs) =>
          `Read ${paginationInfo.from}-${paginationInfo.to}, kokku ${paginationInfo.count}`,
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          padding: 0.25, // MUI v5 defines sx paddings already in 8 pt system, so if padding needs to be 2px => 0.25 * 8 = 2
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(2px)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: styles.white,
        },
        elevation1: {
          boxShadow: '0px 0px 10px #00000029',
          backdropFilter: 'unset',
          backgroundColor: styles.white,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          '& .MuiTypography-root': {
            fontWeight: 600,
            color: styles.black,
          },
        },
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-root': {
            padding: '0',
          },
          boxShadow: 'none',
          borderBottom: `1px solid ${styles.disabledGrey}`,
        },
      },
    },
  },
});

export default theme;
