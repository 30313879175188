import React from 'react';
import { ButtonBase, Paper } from '@mui/material';
import TranslatedDataGrid from '../../TranslatedDataGrid/TranslatedDataGrid';
import { GridColDef } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { Role, User } from '../../../utils/permissions/permission-types';
import { RoleEnum } from '../../../utils/authentication/authentication-types';
import { format, parse } from 'date-fns';

interface Props {
  users: any[];
  selectUser: (user: User) => void;
  selectUserToDelete: (user: User) => void;
}

const getColumns = (
  selectUser: (user: User) => void,
  selectUserToDelete: (user: User) => void
): GridColDef[] => {
  const getOrganizationRole = (kovUser: User): Role | undefined => {
    for (const orgRoles of kovUser.organizationRoles) {
      const role = orgRoles.roles.find(
        (role) =>
          role.role === RoleEnum.ROLE_KOV_OFFICIAL ||
          role.role === RoleEnum.ROLE_KOV_CADASTER_REGISTRAR
      );

      if (role) {
        return role;
      }
    }
  };

  return [
    {
      field: 'name',
      headerName: 'Nimi',
      flex: 1,
      renderCell: ({ row: { firstName, lastName } }) => (
        <div>{`${firstName || ''} ${lastName || ''}`}</div>
      ),
      valueGetter: ({ row: { lastName } }) => lastName,
      disableColumnMenu: true,
    },
    { field: 'identificationCode', headerName: 'Isikukood', flex: 1, disableColumnMenu: true },
    { field: 'email', headerName: 'E-mail', flex: 1, disableColumnMenu: true },
    {
      field: 'role',
      headerName: 'Roll',
      flex: 1,
      valueGetter: (params) => {
        const role = getOrganizationRole(params.row);

        if (role) {
          return role.role;
        }
        return '';
      },
    },
    {
      field: 'startDate',
      headerName: 'Kehtivuse algus',
      flex: 1,
      valueGetter: (params) => {
        const role = getOrganizationRole(params.row);

        if (role?.startDate) {
          return format(parse(role.startDate, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy');
        }
        return '';
      },
    },
    {
      field: 'endDate',
      headerName: 'Kehtivuse lõpp',
      flex: 1,
      valueGetter: (params) => {
        const role = getOrganizationRole(params.row);

        if (role?.endDate) {
          return format(parse(role.endDate, 'yyyy-MM-dd', new Date()), 'dd.MM.yyyy');
        }
        return '';
      },
    },
    {
      field: 'view',
      headerName: 'Muuda',
      flex: 0.5,
      renderCell: ({ row }) => (
        <ButtonBase onClick={() => selectUser(row)}>
          <Edit color='primary' />
        </ButtonBase>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'delete',
      headerName: 'Kustuta',
      flex: 0.5,
      renderCell: ({ row }) => (
        <ButtonBase onClick={() => selectUserToDelete(row)}>
          <Delete color='error' />
        </ButtonBase>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];
};

const UserTable = ({ users, selectUser, selectUserToDelete }: Props) => {
  return (
    <Paper
      sx={{
        height: '100%',
        marginTop: '8px',
      }}
    >
      <TranslatedDataGrid columns={getColumns(selectUser, selectUserToDelete)} rows={users} />
    </Paper>
  );
};

export default UserTable;
